footer {
    border-top: 2px solid rgba(255, 255, 255, 0.1);
    background-color: rgba(22, 33, 62, 0.8);
    margin-top: 70px;
    padding: 2rem;
    font-family: "Inconsolata", sans-serif;
    font-size: 1.3rem;
    color: #fff;
    text-align: center;
}

.social-media-container {
    display: flex;
    justify-content: center;
}

.social-media-container a {
    font-size: 2rem;
    color: #fff;
    margin: 0 0.5rem;
    transition: all 0.25s ease;
}

.social-media-container a:hover {
    color: #E94560;
}