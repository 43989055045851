.bigHeader {
    color: #fff;
    font-weight: 600;
    font-size: 4rem;
    font-family: "Inconsolata", sans-serif;
}
h2, h4 {
    font-family: "Inconsolata", sans-serif;
    color: #ffffff;
}

.name-span {
    font-weight: 700;
    color: #E94560;
}

.photo-container {
    width: 100%;
    max-width: 550px;
    margin: auto;
}

.photo-container img {
    width: 100%;
    border-radius: 50%;
    border: 5px solid #E94560;
}

.button-see-what-i-do {
    background-color: #E94560;
    height: 3rem;
    line-height: 2rem;
    border-radius: 1.5rem;
    padding: 0.5rem 2rem 0.5rem 2rem;
    border: none;
    margin: 2rem 0 2rem 0;
    font-size: 1.8rem;
    font-family: "Inconsolata", sans-serif;
    box-shadow: 0 8px 32px 0 rgba(233,69,96,0.37);
}
.button-see-what-i-do:hover, .button-see-what-i-do:active {
    background-color: #E94560 !important;
    box-shadow: 0 8px 32px 0 rgba(233,69,96,0.6);
}