.life-quote {
    font-family: "Inconsolata", sans-serif;
    color: #fff;
    text-align: center;
}

.life-quote p {
    font-size: 2rem;
    font-style: italic;
}

.short-line {
    width: 150px;
    display: block;
    margin: auto;
    border-top: 3px solid #E94560;
}