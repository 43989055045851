@keyframes blink {
    50% {
        opacity: 0;
    }
}

.radar-container {
    width: 100%;
    max-width: 500px;
}

.terminal p {
    margin: 0.5em;
    font-weight: bold;
}
.terminal span {
    color: greenyellow;
}
.terminal span.flashing-cursor {
    animation: 1s blink step-end infinite;
    color: white
}