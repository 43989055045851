@import 'https://fonts.googleapis.com/css2?family=Inconsolata&family=Montserrat:wght@400;500;600;700&display=swap';
$body-bg: #16213E;
$link-color: #fff;

.navbar-toggler-icon,
.navbar-toggler-icon:active,
.navbar-toggler-icon:focus, {
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 30 30%27%3e%3cpath stroke=%27rgba%28255, 255, 255, 0.9%29%27 stroke-linecap=%27round%27 stroke-miterlimit=%2710%27 stroke-width=%272%27 d=%27M4 7h22M4 15h22M4 23h22%27/%3e%3c/svg%3e") !important;
  outline: none !important;
}

html, body {
  font-family: "Inconsolata", sans-serif;
  overflow-x: hidden;
}

a.link, a.link:hover, a.link:active {
  color: #E94560;
  text-decoration: none;
}

.background {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: -500 !important;
  filter: blur(65px);
}

.card-glassmorphism,
.card-glassmorphism-active,
.card-glassmorphism-with-image {
  padding: 1rem;
  background: rgba(22,33,62,0.5);
  font-family: "Inconsolata", sans-serif;
  color: #fff;
  box-shadow: 0 8px 32px 0 rgba(22,33,62,0.37);
  backdrop-filter: blur(12px);
  -webkit-backdrop-filter: blur(12px);
  border: 2px solid rgba( 255, 255, 255, 0.18 );
  border-radius: 10px;
}
.card-glassmorphism-active {background: rgba(255,255,255,0.3);}
.card-glassmorphism-with-image {padding: 0}
.card-glassmorphism-with-image *:not(img){padding: 0 1rem 0 1rem}
.card-glassmorphism-with-image img { border-top-left-radius: 10px; border-top-right-radius: 10px}

.whitespace-divider {
  height: 300px;
  width: 100%;
}
.whitespace-divider-smaller {
  height: 150px;
  width: 100%;
}

.section-header {
  font-size: 3rem;
  font-family: "Inconsolata", sans-serif;
  color: #fff;
  margin-bottom: 2rem;
}

.custom-navbar {
  background-color: #16213E;
  padding: 1.5rem;
  font-family: "Inconsolata", sans-serif;
  color: white !important;
}
.custom-navbar a,
.custom-navbar a:visited {
  color: white !important;
  transition: all 0.25s ease;
  text-decoration: none;
  font-size: 1.2rem;
  margin-left: 1rem;
}
.custom-navbar a:active, .custom-navbar a:hover {
  color: #E94560 !important;
}

@import '~bootstrap/scss/bootstrap.scss';
@import '~bootstrap/scss/nav.scss';
