.custom-input {
    color: white !important;
    border: 1px solid rgba(255,255,255,0.2);
}

.postcard-stamp-container img {
    height: 75px;
}

.button-submit-postcard {
    background-color: #E94560;
    height: 2rem;
    line-height: 1.2rem;
    border-radius: 1rem;
    padding: 0.5rem 2rem 0.5rem 2rem;
    border: none;
    margin-bottom: 1rem;
    font-size: 1rem;
    font-family: "Inconsolata", sans-serif;
    box-shadow: 0 8px 32px 0 rgba(233,69,96,0.37);
}
.button-submit-postcard:hover, .button-submit-postcard:active {
    background-color: #E94560 !important;
    box-shadow: 0 8px 32px 0 rgba(233,69,96,0.6);
}

.contact-form-description {
    color: rgba(255,255,255,0.8);
    margin-top: 2rem;
    font-size: 1.1rem;
}
.contact-form-description span {
    color: #E94560;
}