.swiper-container {
    width: 100%;
    margin: auto;
}

.swiper {
    padding-bottom: 50px;
    max-width: 500px;
}

span.swiper-pagination-bullet.swiper-pagination-bullet-active {
    background-color: #E94560;
    opacity: 1;
    transform: scale(1.5);
}

.button-project {
    background-color: #E94560;
    border-radius: 1.5rem;
    padding: 0.5rem 2rem 0.5rem 2rem !important;
    border: none;
    margin: 0rem 0 1rem 1rem;
    font-size: 1rem;
    font-family: "Inconsolata", sans-serif;
    box-shadow: 0 8px 32px 0 rgba(233,69,96,0.37);
    transition: all 0.25s ease;
}
.button-project:hover {
    box-shadow: 0 8px 32px 0 rgba(233,69,96,0.6);
}

.button-project a,.button-project a:hover {
    padding: 0;
    color: white;
    text-decoration: none;
}

/* target all bullets */
.swiper-pagination-bullet {
    background-color: #fff;
    opacity: 0.8;
}

.card-projects {
    background: rgba(22,33,62,0.9);
}

.project-description {
    padding: 1rem;
    color: white;
    font-size: 1.2rem;
    font-family: "Inconsolata", sans-serif;
}

.example-enter {
    opacity: 0.01;
}

.example-enter.example-enter-active {
    opacity: 1;
    transition: opacity 500ms ease-in;
}

.example-leave {
    opacity: 1;
}

.example-leave.example-leave-active {
    opacity: 0.01;
    transition: opacity 300ms ease-in;
}